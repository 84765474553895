import PagesDetailTabsConfigProps from "../../../Pages/interface/PagesDetailTabsConfigProps.interface";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import home from '../../../core/elements/home';
import Content from '../../../core/elements/content';
import seo from '../../../core/elements/seo';
import share from '../../../core/elements/share';
import React from "react";

const BlogDetailTabsConfig = (props:PagesDetailTabsConfigProps) => {

    let Store = props.Store;
    let config = [{
        tab: {defaultActiveKey:'home',id:'uncontrolled-tab-example',className:'mb-3',key:'tabindex'},
        tabs:[
            {eventKey:'home',title:<IntlMessage Store={Store} messageId="common.general" />,key:'home',id:'home',class:'TabPanel',content:home(
                    {response:props.response2,Store:Store,galerie:props.galerie,galerieItems:props.galerieItems})},
            {eventKey:'content',title:<IntlMessage Store={Store} messageId="common.content" />,key:'content',id:'content',class:'TabPanel',content:Content(
                    {response:props.response2, elements:props.element,
                        setElements:props.setElement, modalShow:props.modalShow, setModalShow:props.setModalShow,
                        modalData:props.modalData, setModalData:props.setModalData,setdeleteElement:props.setdeleteElement,
                        Index:props.Index,setIndex:props.setIndex,Store:props.Store,galerie:props.galerie,galerieItems:props.galerieItems})},
            {eventKey:'seo',title:<IntlMessage Store={Store} messageId="common.seo" />,key:'seo',id:'seo',class:'TabPanel',content:seo(
                    {response:props.response2,seo_title:props.seo_title,
                        setseo_title:props.setseo_title,seo_description:props.seo_description,
                        setseo_description:props.setseo_description,Store:Store})},
            {eventKey:'share',title:<IntlMessage Store={Store} messageId="common.share" />,key:'share',id:'share',class:'TabPanel',content:share(
                    {response:props.response2,Store:Store})}
        ]
    }]

    return config;

}

export default BlogDetailTabsConfig;
