import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import Cards from "../../../../../@WUM/core/component/const/dashboardCards";

const DashbaordOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)

    return (<>
        <Row>
            <Col className={'DashbaordRow text-center'}>
                <h3>CMS Dashboard</h3>
                <Row >
                    <Col><Cards title={'cms.blog.header'}
                                href={'/Blog/1'}
                                text={'cms.blog.description'} Store={Store}/></Col>
                    <Col><Cards title={'cms.page.header'}
                                href={'/Pages/overview/1'}
                                text={'cms.page.description'} Store={Store}/> </Col>
                    <Col><Cards title={'cms.textblocks.header'}
                                href={'/TextBlocks/overview/1'}
                                text={'cms.textblocks.description'} Store={Store}/></Col>
                    <Col><Cards title={'cms.customize.header'}
                                href={'/customizer/1'}
                                text={'cms.customize.description'} Store={Store}/> </Col>
                </Row>

            </Col>
            <Col className={'DashbaordRow text-center'}>
                <h3>Analytics (powered by Matomo)</h3>
                <Row >
                    <Col><Cards title={'matomo.menu.item.dashboard'}
                                href={'/analytics'}
                                text={'matomo.menu.item.dashboard.description'} Store={Store}/></Col>
                    <Col><Cards title={'matomo.menu.item.visitor'}
                                href={'/analytics/visitor'}
                                text={'matomo.menu.item.visitor.description'} Store={Store}/> </Col>
                    <Col><Cards title={'matomo.menu.item.behave'}
                                href={'/analytics/behave'}
                                text={'matomo.menu.item.behave.description'} Store={Store}/></Col>
                    <Col><Cards title={'matomo.menu.item.acquisition'}
                                href={'/analytics/acquisition'}
                                text={'matomo.menu.item.acquisition.description'} Store={Store}/> </Col>
                </Row>
            </Col>
        </Row>
        <br />
            <Row>
                <Col className={'DashbaordRow text-center'}>
                    <h3>Monitoring Dashboard</h3>
                    <Row >
                        <Col><Cards title={'Dashboard'}
                                    href={'/Monitoring/overview/1'}
                                    text={'monitoring.dashboard.description'} Store={Store}/></Col>
                        <Col><Cards title={'Monitoring'}
                                    href={'/Monitoring/API/overview/1'}
                                    text={'monitoring.monitoring.description'} Store={Store}/> </Col>
                    </Row>
                </Col>
            <Col className={'DashbaordRow text-center'}>

                <h3><IntlMessage Store={Store} messageId={'common.settings'}/></h3>
                <Row>
                    <Row>
                        <Col><Cards title={'settings.Member.header'}
                                    href={'/Member/overview/1'}
                                    text={'settings.member.description'} Store={Store}/></Col>
                    </Row>
                </Row>
            </Col>
            <Col className={'DashbaordRow text-center'}>

                <h3>IT-Support</h3>
                <Row>
                    <Row>
                        <Col><Cards title={'menu.item.dashboard'}
                                    href={'/ticket/overview/:page'}
                                    text={'menu.item.dashboard.description'} Store={Store}/></Col>
                    </Row>
                    <Row>
                        <Col><Cards title={'menu.item.labels'}
                                    href={'/ticket/Label/overview'}
                                    text={'menu.item.labels.description'} Store={Store}/></Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    </>)
}

export default DashbaordOverview;


