import {init2} from "../../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {notify_save_intvall_reload} from "../../../../@WUM/core/component/const/notification.service";
import {error} from "../../../../@WUM/core/component/const/error.services";
import updateDataMapping from "./updateData.mapping";

/**
 * Sendet Formular Daten an die API
 * @param status
 * @param id
 * @param currentTutorial
 * @param setCurrentTutorial
 * @param Nowrequest
 * @param part
 * @param Store
 * @param forms
 */
const updateData = (status: boolean, id: any,currentTutorial:any,setCurrentTutorial:any,Nowrequest:any,part:any,Store:any,forms?:any) => {

    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    /*let Data = init();

    console.log(Data)*/
    let Data = init2(forms)

    console.log(Data)

    let requestData = updateDataMapping({part:part,currentTutorial:currentTutorial,Data:Data})

    console.log(requestData)
    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */




    Nowrequest.updatePost(requestData, id)
        .then((response: any) => {
            setCurrentTutorial({...currentTutorial});
            notify_save_intvall_reload(Store);
            console.log(response);
            setTimeout(() => window.location.reload(), 3000);
            $('#btn_save')[0].setAttribute('disabled','disbaled');
        })
        .catch((e: any) => {
            error(e);
        });


};

export default updateData;

