import React from 'react';
import Board from '../components/board'
import Header from '../components/header'
import AllIssue from '../../API/allIssue';
import Allswimelanes from '../../API/allswimelanes';
import {isEmptyArray} from 'formik';
import {SpinnerJSX} from '../components/Spinner';
import Allrow from '../../API/allrow';
//import IntlMessage from '../../../components/const/IntlMessage';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/overview.css';
import TicketoverviewProps from '../../Interface/Ticketoverview.interface';


const Ticketoverview = (props:TicketoverviewProps) => {
  let Issue = AllIssue();
  let swimelane = Allswimelanes();
  let row = Allrow();
  let config = [
    {issue: Issue}, // array ID 0
    {swimelane: swimelane}, // array ID 1
    {row:row} // array ID 2
  ]

  if(Issue !== undefined && !isEmptyArray(Issue) &&
    swimelane !== undefined && !isEmptyArray(swimelane) &&
    row !== undefined && !isEmptyArray(row)) {
  return (
    <>

      <div className={'ContentWrapper'}>
        <Tabs defaultActiveKey="board" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
          <Tab eventKey="board" title={'Board'} key={'Board'} id={'Board'} className="padmarcolor">
            <Header />
            <Board config={config} archiv={false}/>
          </Tab>
          <Tab eventKey="sprint" title={'Sprint'} key={'Sprint'} id={'Sprint'} className="padmarcolor">
            <div className={'MainBackground padcolor'}>
              <Row>
                <Col>
                  Comming Soon
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="archiv" title={'Archiv'} key={'Archiv'} id={'Archiv'} className="padmarcolor">
            <Board config={config} archiv={true}/>
          </Tab>
        </Tabs>


      </div>
  </>)
} else {
    return (<><SpinnerJSX messageId={'common.loading'} /></>)
  }
}

export default Ticketoverview;