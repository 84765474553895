import image_title from './pageElements/image_title';
import text_center from './pageElements/text_center';
import text from './pageElements/text';
import text_blocker from './pageElements/text_blocker';
import divider from './pageElements/divider';
import cta from './pageElements/cta';
import module_customer_steps from './pageElements/module_customer_steps';
import module_text_image_old from './pageElements/module_text_image';
import text_image from './pageElements/text_image';
import search_result from './pageElements/search_result';
import module_duo from './pageElements/module_duo';
import module_content from './pageElements/module_content';
import text_teaser from './pageElements/text_teaser';
import module_blog from './pageElements/module_blog';
//import search_result_products from './pageElements/search_result_product';
import search_result_merchant from './pageElements/search_result_merchant';
import anchor from './pageElements/anchor';
import html from './pageElements/html';
import headline from './pageElements/headline';
import image from './pageElements/image';
import gallery from './pageElements/gallery';
import module_social_media from './pageElements/module_social_media';
import module_video_duo from './pageElements/module_video_duo';
import calender from './pageElements/calender';
import module_title from './pageElements/module_title';
import module_searchform from './pageElements/module_searchform';
import rs from "./pageElements/rs";
import re from "./pageElements/re";
import sitemap from "./pageElements/sitemap";
import textwithIcon from "./pageElements/text_icon";
import slider from "./pageElements/slider";
import accordion from "./pageElements/accordion";
import slider_start from "./pageElements/slider_start";
import slider_end from "./pageElements/slider_end";
import carouselItem_Start from "./pageElements/carouselItem_start";
import carouselItem_end from "./pageElements/carouselItem_end";

function renderSwitch(data: { elements: { [x: string]: any; }; conf: { [x: string]: any; }; }, i: number, newElement:any = false,newelementIndex:any = 0,
                      modalShow: boolean, setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                      setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,setdeleteElement:any = false,setElements:any = [],Store:any,galerie?:any,
    galerieItems?:any)
{

  if(data.elements[i].type === 'search_result_products'){
    data.elements[i].type = 'search_result';
    data.elements[i].search_entity = 'products';
  } else if(data.elements[i].type === 'search_result_merchant'){
    data.elements[i].type = 'search_result';
    data.elements[i].search_entity = 'merchants';
  }  else if(data.elements[i].type === 'text_center'){
    data.elements[i].type = 'text';
  }

  switch(data.elements[i].type) {
    case 'image_title':
      return image_title(data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store,galerie,galerieItems);
    case 'text_center':
      return text_center(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'text':
      return text(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'slider':
      return slider(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'accordion':
      return accordion(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case "text_icon":
      return textwithIcon(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'anchor':
      return anchor(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'html':
      return html(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'text_blocker':
      return text_blocker(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'divider':
      return divider(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'sitemap':
      return sitemap(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'row_start':
      return rs(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'row_end':
      return re(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'slider_start':
      return slider_start(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'slider_end':
      return slider_end(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'carouselItem_Start':
      return carouselItem_Start(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'carouselItem_end':
      return carouselItem_end(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'cta':
      return cta(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'module_customer_steps':
      return module_customer_steps(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store,galerie,galerieItems);
    case 'media_text':
      return module_text_image_old(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store,galerie,galerieItems);
    case 'text_image':
      return text_image(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store,galerie,galerieItems);
    case 'headline':
      return headline(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'gallery':
      return gallery(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'image':
      return image(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store,galerie,galerieItems);
    case 'social_media':
      return module_social_media(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'module_video_duo':
      return module_video_duo(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'text_teaser':
      return text_teaser(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'calender':
      return calender(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'module_title':
      return module_title(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'module_searchform':
      return module_searchform(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'search_result':
      if(data.elements[i].search_entity === 'products'){
        return  search_result(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
      } else if(data.elements[i].search_entity === 'merchants') {
        return  search_result_merchant(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
      } else {
        return  search_result(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
      }
  case 'module_content':
      return module_content(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store,galerie,galerieItems);
    case 'module_duo':
      return  module_duo(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
    case 'blog_teaser':
      return  module_blog(data.conf[i],data.elements[i],i,newElement,newelementIndex,modalShow, setModalShow,modalData, setModalData,setdeleteElement,setElements,Store);
  }
}

export default renderSwitch;
