import IntlMessage, {IntlMessageValue} from '../../../../../@WUM/core/component/const/IntlMessage';
import React from "react";

function configuration(conf: string,response:any,newElement:any = false,Store:any) {
  let cutDate = ((newElement === false ? (response.publishedat !== null && response.publishedat !== null ? response.publishedat.split(' ')[0] : '') : ''));


  const titel = (<><h2><IntlMessage Store={Store} messageId="configuration.home.title" /></h2></>);

  const description = (<><p><IntlMessage Store={Store} messageId="configuration.home.description" /></p></>);

  const dataColOne = [{
    'formType': 'input',
    'type': 'hidden',
    'name': 'discr',
    'value': (newElement === false ? response.discr : ''),
  },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : ''),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'translations[id]',
      'value': (newElement === false ? response.translation.de.id : ''),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'translations[locale]',
      'value': (newElement === false ? response.translation.de.locale : 'de'),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'identifier',
      'id': 'identifier',
      'value': (newElement === false ? response.translation.de.slug : ''),
    },

    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'layout',
      'value': (newElement === false ? response.layout : ''),
    },
    {
      'formType': 'input',
      'type': 'text',
      'name': 'translations[slug]',
      'id':'slug',
      'placeholder':  IntlMessageValue('de',"common.change_url",Store),
      'label': <IntlMessage Store={Store} messageId="common.change_url.label" />,
      // note für append into another element value
      'appendid': 'identifier',
      'legende': IntlMessageValue('de','common.change_url.legende',Store),
      'value': (newElement === false ? response.translation.de.slug : ''),
    },{
    'formType': 'input',
    'label': <IntlMessage Store={Store} messageId="common.title" />,
    'required': true,
    'max': 70,
    'type': 'text',
    'placeholder':  IntlMessageValue('de',"common.provide_pagetitle",Store),
    'name': 'translations[title]',
    'value': (newElement === false ? response.translation.de.title : ''),
  },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.released" />,
      'type': 'date',
      'placeholder': 'xx.xx.xxxx',
      'value': cutDate,
    },
    {
      'formType': 'textarea',
      'label': <IntlMessage Store={Store} messageId="common.short_description" />,
      'required': true,
      'max': 160,
      'type': 'textarea',
      'forbidden': true,
      'row': 3,
      'name': 'translations[description]',
      'value': (newElement === false ? response.translation.de.description : ''),
    }
  ];

  const dataColtwo = [
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.menu" />,
      'name': 'menu_event',
      'selected': (newElement === false ? response.menu_event : ''),
      'options': [{
        'label': '',
        'value': 'null',
      },
        {
          'label':  IntlMessageValue('de',"common.main_menu",Store),
          'value': 'menu.main'
        },
        {
          'label':  IntlMessageValue('de',"common.footer_menu",Store),
          'value': 'menu.footer'
        }]

    }, {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.position" />,
      'type': 'number',
      'required': true,
      'placeholder':  IntlMessageValue('de',"common.insert_number",Store),
      'name': 'position',
      'value': (newElement === false ? (response.position === '' ? 1 : response.position) : 1),
    },
    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.visible" />,
      'name': 'visible',
      'selected': (newElement === false ? response.visible : 0),
      'options': [
        {
          'label': IntlMessageValue('de',"common.activated",Store),
          'value': '1'
        },
        {
          'label': IntlMessageValue('de',"common.deactivated",Store),
          'value': '0'
        }]

    },
    {
      'formType': 'fetchselect',
      'local': 'fetchSelect',
      'valueState': 'id',
      'beginn': 'true',
      'labelState': 'title',
      'selected': (newElement === false ? response.parent : 0),
      'label': <IntlMessage Store={Store} messageId="common.page_parent" />
    }

  ]

  if(conf === 'titel') {
    return titel;
  }  else if(conf === 'description') {
    return description;
  } else if(conf === 'dataColOne') {
    return dataColOne;
  } else if(conf === 'dataColtwo') {
    return dataColtwo;
  }

}

export default configuration;
