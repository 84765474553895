import React from 'react';
import PielersForm from '../../index'
import {Accordion, Tabs} from 'react-bootstrap';
import {base, basesettings} from './base';
import {ElementsHead} from '../../../component/const/ElementsHead';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import MergeTwoObjecttoArray from "../../../component/const/MergeTwoObjecttoArray";

function textwithIcon(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)
  const fake_headlines = [1,2,3,4,5,6].map(n => ({
    'label': IntlMessageValue('de',"module.fake_headline_n",Store,"replace", "[n]", n),
    'value': n,
  }));

  const headlines = [1,2,3,4,5,6].map(n => ({
    'label': IntlMessageValue('de',"module.headline_n",Store,"replace", "[n]", n),
    'value': n+6,
  }));

  let options = MergeTwoObjecttoArray(fake_headlines,headlines)

  const configuration = [

    {
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="module.headline_type" />,
      'name': 'elements[' + index + '][headline_type]',
      'legende': <IntlMessage Store={Store} messageId="common.status" />,
      'selected': (newElement === false ? element.headline_type : ''),
      'options': options,
    },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline" />,
      'name': 'elements[' + index + '][translation][headline]',
      'type': 'text',
      'placeholder': "lang[common.insert_headline]",
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.headline : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/text_icon.html',
    }

    ];

  const Items = [
    { 'formType': 'IconHolder',
      'label': 'Icon',
      'index': index,
      'type': 'text',
      'placeholder': 'Icon',
      'parent': element.id,
      'name': 'elements['+index+'][translation][items]',
      'value': (newElement === false ? (element.translation !== undefined ? element.items : '') : ''),
    },
  ]

  const ctaButton = [
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.url" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.url",Store),
      'name': 'elements['+index+'][translation][url]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.url : '' ) : ''),
    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.button_text" />,
      'type': 'text',
      'max': 30,
      'placeholder': IntlMessageValue('de',"common.button_text",Store),
      'name': 'elements['+index+'][translation][cta_text]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.cta_text : '' ) : '' ),
    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.button_title" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.button_title",Store),
      'name': 'elements['+index+'][translation][cta_title]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.cta_title : '' ) : ''),
    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.target" />,
      'name': 'elements['+index+'][translation][target]',
      'selected': (newElement === false ? (element.translation !== undefined ? element.translation.de.target  : '' ) : ''),
      'options': [
        {
          'label': IntlMessageValue('de',"common.standard",Store),
          'value': 1
        },
        {
          'label': IntlMessageValue('de',"common.new_window",Store),
          'value': 2
        },
        {
          'label': IntlMessageValue('de',"common.parent_window",Store),
          'value': 3
        },
        {
          'label': IntlMessageValue('de',"common.same_window",Store),
          'value': 4
        }]

    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.css_class" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.css_class",Store),
      'name': 'elements['+index+'][Class]',
      'value': (newElement === false ? element.Class : '' ),
    },
  ]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
        <Accordion.Item eventKey={index} id={'text'+index}   style={{marginBottom: '10px'}}>
          {ElementsHead('Text Icon','text',Status,index,'elementButton','Text Icon',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store)}
          <Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="item" title={'Icon Item'} key={'item'} id={'item'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(Items)}
              </Tab>
              <Tab eventKey="cta" title={'CTA Button'} key={'cta'} id={'cta'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(ctaButton)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>

    </>
  )


}

export default textwithIcon;
