import {RegisterRoute} from "../../../../Register/RegisterRoute";

const RoutingConfig = (value: any) => {

    return [
        ...RegisterRoute(value)
    ];

}

export default RoutingConfig;