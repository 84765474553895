import React from 'react';
import MonitoringOverview from "./Pages/overview";
import MonitoringSystemAPIOverview from "./Pages/MonitoringSystemAPIOverview";
import MonitoringSystemAPIOutbound from "./Pages/MonitoringSystemAPIOutbound";

export const MonitoringPagesConfig =(value:any) => {

  return [
      {
        path: '/Monitoring/overview/:page',
        elements: <MonitoringOverview value={value} />,
      },
      {
        path: '/Monitoring/API/overview/:page',
          elements: <MonitoringSystemAPIOverview values={value} />,
      },
      {
        path: '/Monitoring/email/overview/:page',
          elements: <MonitoringSystemAPIOutbound value={value} />,
      },
];
}

export const MonitoringMenuConfig =(value:any) => {
  return [
      {
          name: 'Dashboard',
          route: '/Monitoring/overview/1',
          icon: 'description'
      },
    {
      name: 'Monitoring',
      route: '/Monitoring/API/overview/1',
      icon: 'description'
    }
  ]
}
