import {siteApiDetailType} from "../../Pages/API/request/site/siteApiDetail.interface";
import {config, output} from "../../Pages/modules/configuration/mapping";
import {configBlog, outputBlog} from "../../Blog/modules/configuration/mapping";
import {blogApiDetail} from "../../Blog/API/request/blog/blogApiDetail.interface";
import {customizerApiDetailType} from "../../customize/API/request/customizer/customizerApiDetail.interface";
import updateDataMappingProps from "../interface/updateDataMappingProps.interface";


const updateDataMapping = (props:updateDataMappingProps) => {

    switch (props.part) {
        case 'site':
            let requestDataSite: siteApiDetailType[] & config = Object.assign({}, props.currentTutorial, output(props.Data));
            return requestDataSite;
        case 'blog':
            let requestDataBlog: blogApiDetail[] & configBlog = Object.assign({}, props.currentTutorial, outputBlog(props.Data));
            return requestDataBlog;
        case 'customizer':
            let requestDataCustomizer: customizerApiDetailType[] & config = Object.assign({}, props.currentTutorial, output(props.Data));
            return requestDataCustomizer;
    }
}

export default updateDataMapping;
