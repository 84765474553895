import {UserActionType} from "../../actions/ActionType";

const initialState = {
    loading: false,
    username: null,
    email: null,
}

type action = {
    type: string
    username?: any
    email?: any
    error?: any
}

const UserReducer = (state = initialState,
                        action: action) => {
    switch (action.type) {
        case UserActionType.User_INIT:
            return {
                ...state,
                username: action.username,
                email: action.email,
                loading: true,
            }
        default:
            return state
    }
}

export default UserReducer;
