import React from 'react';
import {Accordion, Tabs} from 'react-bootstrap';
import PielersForm from '../../index';
import {base, basesettings} from './base';
import {ElementsHead} from '../../../component/const/ElementsHead';
import IntlMessage from "../../../component/const/IntlMessage";
import Tab from "react-bootstrap/Tab";

function rs(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                 setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                 setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                 content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)
  const configuration = [

    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/rs.html',
    }
  ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));
  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
        <Accordion.Item eventKey={index} id={'divider'+index}   style={{marginBottom: '10px'}}>
          {ElementsHead(<IntlMessage Store={Store} messageId='module.row_start' />,'divider',Status,index,'elementButton','Text',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store)}
          <Accordion.Body>
            <Tabs defaultActiveKey="Einstellungen" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
                {PielersForm(newConfiguration)}
              </Tab>
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>

    </>
  )


}

export default rs;
