import React from 'react';
import CustomizerOverview from "./Pages/overview";
import CustomizerDetail from "./Pages/Detail";
import {LanguageSwitcherValue} from "../../core/components/LanguageSwitcher";

export const CustomizerPagesConfig = (value:any) => {
  return [
    {
      path: '/customizer/:page',
      elements: <CustomizerOverview value={value}/>,
    },
    {
      path: '/customizer/Detail/:id',
      elements: <CustomizerDetail value={value}/>,
    }
  ];
}

export const CustomizerMenuConfig = (value:any) => {
  return [
    {
      name: LanguageSwitcherValue("cms.customize.header"),
      route: '/customizer/1',
      icon: 'dashboard_customize'
    }
  ];
}
