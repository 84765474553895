import React from "react";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import home from "../elements/home";
import Content from "../elements/content";
import seo from "../elements/seo";
import PagesDetailTabsConfigProps from "../../interface/PagesDetailTabsConfigProps.interface";
import share from "../../../core/elements/share";

const PagesDetailTabsConfig = (props:PagesDetailTabsConfigProps) => {

    let config = [{
        tab: {defaultActiveKey:'home',id:'uncontrolled-tab-example',className:'mb-3',key:'tabindex'},
        tabs:[
            {eventKey:'home',title:<IntlMessage Store={props.Store} messageId="common.general" />,key:'home',id:'home',class:'TabPanel',content:home(
                {response:props.response2,Store:props.Store})},
            {eventKey:'content',title:<IntlMessage Store={props.Store} messageId="common.content" />,key:'content',id:'content',class:'TabPanel',content:Content(
                {response:props.response2, elements:props.element,
                    setElements:props.setElement, modalShow:props.modalShow, setModalShow:props.setModalShow,
                    modalData:props.modalData, setModalData:props.setModalData,setdeleteElement:props.setdeleteElement,
                    Index:props.Index,setIndex:props.setIndex,Store:props.Store,galerie:props.galerie,galerieItems:props.galerieItems})},
            {eventKey:'seo',title:<IntlMessage Store={props.Store} messageId="common.seo" />,key:'seo',id:'seo',class:'TabPanel',content:seo(
                {response:props.response2,seo_title:props.seo_title,
                    setseo_title:props.setseo_title,seo_description:props.seo_description,
                    setseo_description:props.setseo_description,Store:props.Store})},
            {eventKey:'share',title:<IntlMessage Store={props.Store} messageId="common.share" />,key:'share',id:'share',class:'TabPanel',content:share(
                    {response:props.response2,Store:props.Store})}
        ]
    }]

    return config;

}

export default PagesDetailTabsConfig;
