import {isEmptyArray} from 'formik';
import HelperTechTechProps from '../../Interface/HelperTechTech.interface';



  
  export const HelperTechTech = (props:HelperTechTechProps) => {
  
    let IT_MAHolder = [];
    props.issue.IT_MA = [
      {
        "username": "Marcel Ugurcu",
        "is_superuser": true
      }
    ]
    for (let x = 0; x < props.issue.IT_MA.length; x++) {
      IT_MAHolder.push(props.issue.IT_MA[x].developer);
    }
    if (props.tech !== undefined && !isEmptyArray(props.tech)) {
      let OptionSwimlane = [];
      for (let x = 0; x < props.tech.length; x++) {
        let dataAttribute;
        let type = '';
        if (IT_MAHolder.includes(props.tech[x].username)) {
          type = '&#9745;'
          dataAttribute = 'Delete';
        } else {
          dataAttribute = 'Post';
  
        }
  
        let options = type + ' ' + props.tech[x].username;
  
        OptionSwimlane.push(<>
          <option
            id={'optionTech' + props.tech[x].username.replaceAll(' ','_')}
            value={props.tech[x].username}
            data-param={dataAttribute}
            dangerouslySetInnerHTML={{__html: options}}
          />
  
        </>)
      }
  
  
    return(<>{OptionSwimlane}</>)
    } else {
      return (<></>)
    }
  
  }

  export default HelperTechTech;