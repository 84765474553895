import {CMSMenuConfig} from "../modules/CMS";
import {MatomoMenuConfig} from "../modules/matomo/modules";
import {TicketMenuConfig} from "../modules/ticketsystem/modules";
import {ShopMenuConfig} from "../modules/shop";
import {monitoringMenuConfig} from "../modules/monitoring/modules";
import {SettingsMenuConfig} from "../modules/settings";


export const RegisterSitemap = (value: any) => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                ...CMSMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...TicketMenuConfig(value)

            ]
        case 'CMSwithMatomo':
            return [
                ...CMSMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...TicketMenuConfig(value)

            ]
        case 'SHOP':
            return [
                ...CMSMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...TicketMenuConfig(value)
            ]
        case 'SHOPwithMatomo':
            return [
                ...CMSMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...TicketMenuConfig(value)
            ]
        default:
            return [
                ...CMSMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...TicketMenuConfig(value)
            ]
    }

}
