import React from 'react';
import {Accordion, Tabs} from 'react-bootstrap';
import PielersForm from '../../index';
import {basesettings} from './base';
import {ElementsHead} from '../../../component/const/ElementsHead';
import Tab from "react-bootstrap/Tab";
import IntlMessage from "../../../component/const/IntlMessage";

function divider(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                 setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                 setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                 content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'divider'
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][type]',
      'type': 'hidden',
      'value': element.type,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][id]',
      'type': 'hidden',
      'value': element.id,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][new]',
      'type': 'hidden',
      'value': (newElement === false ? false : true),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][position]',
      'type': 'hidden',
      'value': (newElement === false ? element.position : index),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][updated_at]',
      'type': 'hidden',
      'value': element.updated_at,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][type_id]',
      'type': 'hidden',
      'value': (newElement === false ? element.type_id : window.location.pathname.split('/').pop()),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/divider.html',
    }
    ]

  let newConfiguration = configuration;

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
        <Accordion.Item eventKey={index} id={'divider'+index}   style={{marginBottom: '10px'}}>
          {ElementsHead(<IntlMessage Store={Store} messageId="module.divider" />,'divider',Status,index,'elementButton','Text',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store)}
          <Accordion.Body>
            {PielersForm(newConfiguration)}
            <Tabs defaultActiveKey="Einstellungen" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>

    </>
  )


}

export default divider;
