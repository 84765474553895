import React from 'react';
import {LanguageSwitcherValue} from "../../core/components/LanguageSwitcher";
import Pages from "./Pages/Pages";
import PageNew from "./Pages/new";
import PagesDetail from "./Pages/PagesDetail";

export const PagesConfig = (value: any) => {

    return [
        {
            path: '/Pages/overview/:page',
            elements: <Pages value={value}/>,
        },
        {
            path: '/Pages/new/new',
            elements: <PageNew value={value}/>,
        },

        {
            path: '/Pages/new/:page',
            elements: <PageNew value={value}/>,
        },
        {
            path: '/Pages/Detail/:id',
            elements: <PagesDetail value={value}/>,
        },
        {
            path: '/Pages/Delete/:page/:deleteID',
            elements: <Pages value={value}/>,
        },

    ];
}

export const PagesMenuConfig = (value:any) => {
    return [{
        name: LanguageSwitcherValue("cms.page.header"),
        route: '/Pages/overview/1',
        icon: 'account_tree'
    }
        ]
}
