import React from 'react';
import PielersForm from '../../index'
import {Accordion, Tabs,Button} from 'react-bootstrap';
import {base, basesettings} from './base';
import {ElementsHead} from '../../../component/const/ElementsHead';
import IntlMessage from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import ReactDOM from "react-dom";

function slider(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'input',
      'label': 'Slider Name',
      'name': 'elements[' + index + '][translation][name]',
      'type': 'text',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.name : '') : ''),
    },
    {
      'formType': 'input',
      'label': 'Slider Class',
      'name': 'elements[' + index + '][translation][class_carousel]',
      'type': 'text',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.class_carousel : '') : ''),
    },
    {
      'formType': 'input',
      'label': 'inner Carousel Class',
      'name': 'elements[' + index + '][translation][innercarousel]',
      'type': 'text',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.innercarousel : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/slider.html',
    },
    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }

  function JSONNewAppend() {

    let JsonP = JSON.parse((document.getElementById('json' + index) as HTMLInputElement).value);

    console.log(JsonP.item);

    let newPart = {
      'name': '',
      'alt': '',
      'link':'',
      'text':'',
      'id': null,
      'parent': 0,
      'locale': 'de',
    };

    let tmpItem = [];

    for(let x=0;x<JsonP.item.length;x++) {
      tmpItem.push(JsonP.item[x])
    }
    tmpItem.push(newPart);

    JsonP.item = tmpItem;

    (document.getElementById('json' + index) as HTMLInputElement).value = JSON.stringify(JsonP);

    let conf = [
      {
        'formType': 'multiply',
        'RowId':'slide'+(JsonP.item.length -1)+'_'+index,
        'cols': [
          {
            'formType': 'image',
            'altLink': true,
            'id': 'Logo_Image'+(JsonP.item.length -1)+'_'+index,
            'path': 'test',
            'width': '44',
            'height': '44',
            'class': 'elementsIMg',
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            }
          }, {
            'formType': 'formFile',
            'onclick': true,
            'id': 'logo',
            'onclick_context': 'marketplace_market_square_400',
            'expandfile': 'logo_id',
            'mode': true,
            'label': "name",
            'append': 'logo_src'+(JsonP.item.length -1)+'var_'+index,
            'src': 'Logo_Image'+(JsonP.item.length -1)+'_'+index,
            'jsonID':'json' + index,
            'appendJson': JsonP.item.length -1,
            'appendJsonField': 'name',
            'experiment': true,
            'size': {
              'xl': 3,
              'md': 3,
              'sm': 2,
            }
          }, {
            'formType': 'input',
            'label': 'alt',
            'type': 'text',
            'value': '',
            'jsonID': 'json' + index,
            'id': 'alt' + (JsonP.item.length - 1) + 'var_' + index,
            'appendJson': JsonP.item.length - 1,
            'appendJsonField': 'alt',
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            },
          },
          {
            'formType': 'textarea',
            'label': 'text',
            'type': 'textarea',
            'value': '',
            'jsonID': 'json' + index,
            'id': 'text' + (JsonP.item.length - 1) + 'var_' + index,
            'appendJson': JsonP.item.length - 1,
            'appendJsonField': 'text',
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            },
          },
          {
            'formType': 'input',
            'label': 'link',
            'type': 'text',
            'value': '',
            'jsonID':'json' + index,
            'id':'link'+(JsonP.item.length -1)+'var_' + index,
            'appendJson': JsonP.item.length -1,
            'appendJsonField': 'link',
            'size': {
              'xl': 1,
              'md': 1,
              'sm': 1,
            },
      },
          {
            'formType': 'button',
            'label': 'Slide entfernen',
            'variants': 'danger',
            'removeID':JsonP.item.length -1,
            'removeIndex':index,
            'namespace':'slide',
            'jsonID':'json' + index,
            'size': {
              'xl': 2,
              'md': 2,
              'sm': 2,
            }
          },
          {
            'formType': 'input',
            'name': 'name',
            'visible': true,
            'label': 'Logo src',
            'type': 'hidden',
            'id':'logo_src'+(JsonP.item.length -1)+'var_'+index,
            'placeholder': 'imageID',
            'value': ''
          }

        ]
      }
    ]

    console.log(JsonP);
    let newElement = <>{PielersForm(conf)}</>;
    let temp = document.createElement('div');
    ReactDOM.render(newElement, temp);
    let ele =  document.getElementById('uncontrolled-tab-example-tabpane-slides'+index);
    if(ele !== null) {
      ele.appendChild(temp)
    }
  }

  return (
    <>
        <Accordion.Item eventKey={index} id={'slider'+index}   style={{marginBottom: '10px'}}>
          {ElementsHead(<IntlMessage Store={Store} messageId="module.slider" />,'slider',Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.slider" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store)}
          <Accordion.Body>

            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey={"slides"+index} title={<IntlMessage Store={Store} messageId="settings.settings.slides" locale={'de'} />} key={"slides"+index} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                <Button variant={'success'} onClick={()=>JSONNewAppend()}>Neuen Slide hinzufügen</Button>
                {buildItem(element,index,newElement)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>
          </Accordion.Body>
        </Accordion.Item>

    </>
  )
}

export default slider;


const buildItem = (element:any,index:any,newElement:any) => {

  let Items = [];
  if (newElement === false) {
    let elementItem = element.translation.de.item;

    let defaultJson = {"item": elementItem}




    if (elementItem !== undefined) {

      for (let x = 0; x < elementItem.length; x++) {
        Items.push(
            {
              'formType': 'multiply',
              'RowId':'slide'+x+'_'+index,
              'cols': [
                {
                  'formType': 'image',
                  'altLink': true,
                  'id': 'Logo_Image'+x+'_'+index,
                  'path': elementItem[x].name,
                  'width': '44',
                  'height': '44',
                  'class': 'elementsIMg',
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  }
                }, {
                  'formType': 'formFile',
                  'onclick': true,
                  'id': 'logo',
                  'onclick_context': 'marketplace_market_square_400',
                  'expandfile': 'logo_id',
                  'mode': true,
                  'label': "name",
                  'append': 'logo_src'+x+'var_'+index,
                  'src': 'Logo_Image'+x+'_'+index,
                  'jsonID':'json' + index,
                  'appendJson': x,
                  'appendJsonField': 'name',
                  'experiment': true,
                  'size': {
                    'xl': 3,
                    'md': 3,
                    'sm': 2,
                  }
                },{
                  'formType': 'input',
                  'label': 'alt',
                  'type': 'text',
                  'value': elementItem[x].alt,
                  'jsonID':'json' + index,
                  'id':'alt'+x+'var_' + index,
                  'appendJson': x,
                  'appendJsonField': 'alt',
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  }
                },
                {
                  'formType': 'textarea',
                  'label': 'text',
                  'type': 'textarea',
                  'value': elementItem[x].text,
                  'jsonID': 'json' + index,
                  'id': 'text' + x + 'var_' + index,
                  'appendJson': x,
                  'appendJsonField': 'text',
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  },
                },
                {
                  'formType': 'input',
                  'label': 'link',
                  'type': 'text',
                  'value': elementItem[x].link,
                  'jsonID':'json' + index,
                  'id':'link'+x+'var_' + index,
                  'appendJson': x,
                  'appendJsonField': 'link',
                  'size': {
                    'xl': 1,
                    'md': 1,
                    'sm': 1,
                  },
                },
                {
                  'formType': 'button',
                  'label': 'Slide entfernen',
                  'variants': 'danger',
                  'removeID':x,
                  'namespace':'slide',
                  'removeIndex':index,
                  'jsonID':'json' + index,
                  'size': {
                    'xl': 2,
                    'md': 2,
                    'sm': 2,
                  }
                },
                {
                  'formType': 'input',
                  'name': 'name',
                  'visible': true,
                  'label': 'Logo src',
                  'type': 'hidden',
                  'id':'logo_src'+x+'var_'+index,
                  'placeholder': 'imageID',
                  'value': elementItem[x].name
                }
              ]
            }
        )

      }
    }
      Items.push({
        'formType': 'input',
        'label': 'json',
        'id':'json' + index,
        'name': 'elements[' + index + '][translation][ItemString]',
        'type': 'hidden',
        'placeholder': "lang[common.insert_headline]",
        'value': JSON.stringify(defaultJson),
      });

      let newConfiguration = Items;

      return PielersForm(newConfiguration)


  } else {
    Items.push({
      'formType': 'input',
      'label': 'json',
      'id':'json' + index,
      'name': 'elements[' + index + '][translation][ItemString]',
      'type': 'hidden',
      'placeholder': "lang[common.insert_headline]",
      'value': '{"item":[]}',
    });

    let newConfiguration = Items;

    return PielersForm(newConfiguration)
  }

}
