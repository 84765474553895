import ApiURL from '../modules/components/ApiURL';
import getToken from '../modules/components/Token';
import ReactDOM from 'react-dom';
import React from 'react';
import IssueBadge from '../modules/components/badge';
import TableCell from '@material-ui/core/TableCell';
import Button from 'react-bootstrap/Button';


export const saveLabelEdit = (e: any) => {
  e.preventDefault();
  let data = e.currentTarget


  console.log('saveLAbel')

  var formdata = new FormData(data);



  var myHeaders = new Headers();

  myHeaders.append("Authorization", 'Bearer ' + getToken());

  var raw = JSON.stringify({
    "id": formdata.get('id'),
    "tg": formdata.get('tg'),
    "bg": formdata.get('bg'),
    "text": formdata.get('text')
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };

  fetch(ApiURL() + "Label/0/", requestOptions)
      .then(response => response.text())
      .then(result => SetChanges(result))
      .catch(error => console.log('error', error));


  const SetChanges = (result:any) => {
    data = JSON.parse(result)
    // @ts-ignore
    document.getElementById('Badge'+data.id).style.background = data.bg
    // @ts-ignore
    document.getElementById('Badge'+data.id).innerHTML = data.text
    // @ts-ignore
    document.getElementById('Badge'+data.id).style.color = data.tg

    // @ts-ignore
    document.getElementById('BgBox'+data.id).style.background = data.bg
    // @ts-ignore
    document.getElementById('text'+data.id).innerHTML = data.text
    // @ts-ignore
    document.getElementById('tgbox'+data.id).style.background = data.tg
  }
  // @ts-ignore
  document.getElementById('Content').style.display = 'none'
  // @ts-ignore
  document.getElementById('editLabel').style.display = 'none'

}

export const saveLabelOverview = (e: any, issueID: any) => {

  function openEdit(bg:any, tg:any, text:any, id:any) {
    let idField = (document.getElementById('id') as HTMLInputElement);
    idField.value = id;

    let bgField = (document.getElementById('bgField') as HTMLInputElement);
    bgField.value = bg;

    let textField = (document.getElementById('textField') as HTMLInputElement);
    textField.value = text;

    let tgField = (document.getElementById('tgField') as HTMLInputElement);
    tgField.value = tg;

    // @ts-ignore
    document.getElementById('editpreviewBadge').style.background = bg

    // @ts-ignore
    document.getElementById('editpreviewBadge').innerHTML = text

    // @ts-ignore
    document.getElementById('editpreviewBadge').style.color = tg

    // @ts-ignore
    document.getElementById('Content').style.display = 'block'
    // @ts-ignore
    document.getElementById('editLabel').style.display = 'block'

  }

  e.preventDefault();
  let data = e.currentTarget
  var formdata = new FormData(data);

  var myHeaders = new Headers();

  var raw = JSON.stringify({
    "tg": formdata.get('tg'),
    "bg": formdata.get('bg'),
    "text": formdata.get('text')
  });

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
  };

  fetch(ApiURL() + "Label/insert/1/", requestOptions)
      .then(response => response.text())
      .then(result => newComment(result))
      .catch(error => console.log('error', error));


  const newComment = (result:any) => {
    data = JSON.parse(result)
    let newElement = (<><TableCell>
      {data.id}</TableCell>
    <TableCell>
      <div id={'BgBox'+data.id} className={'LabelColorBox'} style={{backgroundColor:data.bg}} /></TableCell>
    <TableCell id={'text'+data.id}>{data.text}</TableCell>
    <TableCell>
      <div id={'tgbox'+data.id} className={'LabelColorBox'} style={{backgroundColor:data.tg}} /></TableCell>
    <TableCell><IssueBadge bg={data.bg} tg={data.tg} text={data.text} id={data.id}  /></TableCell>
    <TableCell>
      <Button variant="success"
              onClick={() => openEdit(data.bg,data.tg,data.text,data.id)}
      >Bearbeiten</Button>
    </TableCell></>);
    let temp = document.createElement('tr');
    ReactDOM.render(newElement, temp);
    let ele = document.getElementById('LabelBody');
    if (ele !== null) {
      ele.appendChild(temp)
    }
    // @ts-ignore
    document.getElementById('Content').style.display = 'none'
    // @ts-ignore
    document.getElementById('newLabel').style.display = 'none'
  }
}

export const saveLabel = (e: any, issueID: any) => {

  e.preventDefault();
  let data = e.currentTarget

  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization", "Bearer "+ getToken());

  var formdata = new FormData(data);

  let requestOptions = {
    method: 'Post',
    headers: myHeaders,
    body: formdata
  };

  fetch(ApiURL()+"Label/"+issueID+"/", requestOptions)
    .then(response => response.text())
    .then(result => newComment(result))
    .catch(error => console.log('error', error));

  const newComment = (result:any) => {
    data = JSON.parse(result)
    let newElement = (<>{data.text}</>);
    let temp = document.createElement('option');
    temp.id = 'option'+ data.id
    temp.value = data.id;
    temp.innerHTML = data.text
    temp.dataset.param = 'Post'
    ReactDOM.render(newElement, temp);
    let ele = document.getElementById('selectLabel');
    if (ele !== null) {
      ele.appendChild(temp)
    }
  }
}

const saveLabels = (e: any, issueID: any, label: any) => {

  e.preventDefault();
  let data = e.currentTarget.value


  let setMethod:string = e.target[e.target.selectedIndex].getAttribute('data-param') as string

  e.target.value = ''
  var myHeaders = new Headers();

  var formdata = new FormData();

  formdata.append("label_id", data);

  var raw = JSON.stringify({
    "label_id": formdata.get('label_id'),
    "issue_id": issueID.id
  });

  let requestOptions = {
    method: setMethod,
    headers: myHeaders,
    body: raw
  };

  fetch(ApiURL()+"label-on-issue/"+(setMethod === 'Post' ? "insert" : "delete" )+"/1/", requestOptions)
    .then(response => response.text())
    .then(result => (setMethod === 'Post' ? newComment(result) : DeleteLabel(data)))
    .catch(error => console.log('error', error));



  let optionsfield = (document.getElementById('option'+data) as HTMLOptionElement)

  if(setMethod === 'Post') {
    optionsfield.setAttribute('data-param','Delete');
    optionsfield.innerHTML = optionsfield.innerHTML.replace(optionsfield.innerHTML,'☑ '+optionsfield.innerHTML)
  } else {
    optionsfield.setAttribute('data-param','Post');
    optionsfield.innerHTML = optionsfield.innerHTML.replace('☑','')
  }

  const DeleteLabel = (data:any) => {

    let element = document.getElementById('Badge'+data.label_id);

    // @ts-ignore
    element.parentNode?.removeChild(element);

  }

  const createComment = (data:any) => {
    let Result = JSON.parse(data.item)[0];
    if (Result !== '') {
      let text = Result.text;
      let bg = Result.bg;
      let tg = Result.tg

      return (
        <>
          <IssueBadge bg={bg} text={text} tg={tg} />
        </>
      )
    }
  }

  const newComment = (result:any) => {
    let newElement = createComment({'item': result});
    let temp = document.createElement('span');
    // @ts-ignore
    ReactDOM.render(newElement, temp);
    let ele = document.getElementById('LabelHolder');
    if (ele !== null) {
      ele.appendChild(temp)
    }
  }

}

export default saveLabels;