import {MemberMenuConfig, MemberPagesConfig} from "./Member";
import {ShippingMenuConfig, ShippingPagesConfig} from "./Shipping";
import {TaxMenuConfig, TaxPagesConfig} from "./Tax";
import {UnitMenuConfig, UnitPagesConfig} from "./Unit";
import {TaxonMenuConfig, TaxonPagesConfig} from "./Taxon";
import {PromoMenuConfig, PromoPagesConfig} from "./promo";
import {LanguageSwitcherValue} from "../shop/core/components/LanguageSwitcher";
import {ConfigMenuConfig, ConfigPagesConfig} from "./config";


export const SettingsRouteConfig = (value:any) => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                ...ConfigPagesConfig(value),
                ...MemberPagesConfig(value),
            ]
        case 'CMSwithMatomo':
            return [
                ...ConfigPagesConfig(value),
                ...MemberPagesConfig(value),
            ]
        case 'SHOP':
            return [
                ...ConfigPagesConfig(value),
                ...PromoPagesConfig(value),
                ...MemberPagesConfig(value),
                ...ShippingPagesConfig(value),
                ...TaxPagesConfig(value),
                ...UnitPagesConfig(value),
                ...TaxonPagesConfig(value)
            ]
        case 'SHOPwithMatomo':
            return [
                ...ConfigPagesConfig(value),
                ...PromoPagesConfig(value),
                ...MemberPagesConfig(value),
                ...ShippingPagesConfig(value),
                ...TaxPagesConfig(value),
                ...UnitPagesConfig(value),
                ...TaxonPagesConfig(value)
            ]
        default:
            return [
                ...ConfigPagesConfig(value),
                ...PromoPagesConfig(value),
                //...CustomerPagesConfig(value),
                ...MemberPagesConfig(value),
                //...MemberMerchantPagesConfig(value),
                //...SettingsPagesConfig(value),
                //...SettingsMerchantPagesConfig(value),
                ...ShippingPagesConfig(value),
                ...TaxPagesConfig(value),
                ...UnitPagesConfig(value),
                ...TaxonPagesConfig(value)
            ]
    }
}

export const SettingsMenuConfig = (value:any) => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'home',
                    menuitem: [
                        ...MemberMenuConfig(value),
                        ...ConfigMenuConfig(value)
                    ]
                },
            ]
        case 'CMSwithMatomo':
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'home',
                    menuitem: [
                        ...MemberMenuConfig(value),
                        ...ConfigMenuConfig(value)
                    ]
                },
            ]
        case 'SHOP':
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'home',
                    menuitem: [
                        ...PromoMenuConfig(value),
                        ...MemberMenuConfig(value),
                        ...ShippingMenuConfig(value),
                        ...TaxMenuConfig(value),
                        ...UnitMenuConfig(value),
                        ...TaxonMenuConfig(value),
                        ...ConfigMenuConfig(value)
                    ]
                },
            ]
        case 'SHOPwithMatomo':
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'home',
                    menuitem: [
                        ...PromoMenuConfig(value),
                        ...MemberMenuConfig(value),
                        ...ShippingMenuConfig(value),
                        ...TaxMenuConfig(value),
                        ...UnitMenuConfig(value),
                        ...TaxonMenuConfig(value),
                        ...ConfigMenuConfig(value)
                    ]
                },
            ]
        default:
            return [
                {
                    header:  LanguageSwitcherValue('common.settings'),
                    icon: 'home',
                    menuitem: [
                        ...PromoMenuConfig(value),
                        //...CustomerMenuConfig(value),
                        ...MemberMenuConfig(value),
                        //...MemberMerchantMenuConfig(value),
                        //...SettingMenuConfig(value),
                        //...SettingsMerchantMenuConfig(value),
                        ...ShippingMenuConfig(value),
                        ...TaxMenuConfig(value),
                        ...UnitMenuConfig(value),
                        ...TaxonMenuConfig(value),
                        ...ConfigMenuConfig(value)
                    ]
                },
            ]
    }
    }

