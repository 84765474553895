import React, {Suspense, useState} from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import Site404 from "./404";
import ErrorBoundary from "../../core/routing/ErrorBoundary";
import ReactFlagsSelect from "react-flags-select";
import Avatar from "@material-ui/core/Avatar";
import {IntlMessageValue} from "../component/const/IntlMessage";
import * as actions from "../../templates/default/localization/store/Actions/Locale/LocaleAction";
import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
interface RoutingProps {
    config: {path: string, elements: JSX.Element}[];
    setValue:any,
    value:any
}

const Routing = (props:RoutingProps) => {
    const dispatch : AppDispatch = useDispatch();
    let Store = useSelector((state: any) => state)
    let route = props.config;
    const [selected, setSelected] = useState(localStorage.getItem('locale'));
    let sidebar;
    const setter = (code: React.SetStateAction<string>) => {
        setSelected(code as string)
        props.setValue(code)
        localStorage.setItem('locale',code as string)
        dispatch(actions.getLocale(code as string) as any)
    }

    if(process.env.REACT_APP_SIDEBAR === undefined) {
        sidebar = (<>
            <Sidebar setValue={props.setValue} value={props.value}/>

        </>)
    } else {
        sidebar = (<></>)
    }

    let avatar;
    let username;

    if(Store.user.username !== null && Store.user.username !== undefined ){
        if(Store.user.username.includes(' ')) {
            avatar = Store.user.username.split(' ')[0].charAt(0).toUpperCase() + ''+Store.user.username.split(' ')[1].charAt(0).toUpperCase()
        } else {
            avatar = Store.user.username.split(' ')[0].charAt(0).toUpperCase()
        }
        username = Store.user.username
    } else {
        username = 'Demo User'
        avatar = 'DU';
    }
    return (

        <div className={'flex'}>

        <BrowserRouter >
            <div id={'sidebarCol'}>
           {sidebar}
            </div>
            <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>} >
                <div id={'wrapperContent'} >
                    <div className={'theme-header'}>

                        <div className={'theme-avatar'}>
                            <Avatar style={{float:'left'}}>{avatar}</Avatar> <p className={'avatarName'} style={{paddingTop:'0px'}}>
                            {username}
                            <br /> <a href={"/Logout"}>{IntlMessageValue('de','logout',Store)}</a></p>
                        </div>
                        <div  className={'theme-flags'}>
                            <ReactFlagsSelect

                                selected={selected as string}
                                onSelect={code => setter(code)}
                                countries={["US","DE"]}
                                customLabels={{"US": "EN-US","DE": "DE"}}
                                placeholder="Select Language"
                            /> </div>
                    </div>
                    <div style={{paddingLeft:'20px',paddingRight:'20px',marginTop:'70px',marginBottom:'70px'}}>
                    <Routes>
                {route.map((route, index) => (
                    <Route key={index} path={route.path} element={route.elements} />

                ))}
                        <Route path="*" element={<Site404 />} />
                    </Routes>
                    </div>
                    <div className={'theme-footer'}>
                        <p style={{float:'right'}}>{process.env.REACT_APP_FOOTER_COPYRIGHT !== undefined ? process.env.REACT_APP_FOOTER_COPYRIGHT : '©2022 WUM Solution UG '}</p></div>
                </div>
            </Suspense>
            </ErrorBoundary>
        </BrowserRouter>

        </div>

    )
}

export default Routing;
