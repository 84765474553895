import React from 'react';
import BlogOverview from "./Pages/overview";
import BlogNew from "./Pages/new";
import BlogDetail from "./Pages/Detail";
import {LanguageSwitcherValue} from "../../core/components/LanguageSwitcher";



export const BlogPagesConfig = (value:any) =>{
  return [
    {
      path: '/Blog/:page',
      elements: <BlogOverview value={value}/>,
    },
    {
      path: '/Blog/Delete/:page/:deleteID',
      elements: <BlogOverview value={value}/>,
    },
    {
      path: '/Blog/new/new',
      elements: <BlogNew value={value}/>,
    },
    {
      path: '/Blog/new/:page',
      elements: <BlogNew value={value}/>,
    },
    {
      path: '/Blog/Detail/:id',
      elements: <BlogDetail value={value}/>,
    }
  ];
}

export const BlogMenuConfig = (value:any) =>{
  return [
    {
      name: LanguageSwitcherValue("cms.blog.header"),
      route: '/Blog/1',
      icon: 'post_add'
    },
  ];
}
