import IntlMessage, {IntlMessageValue} from '../../component/const/IntlMessage';
import React from 'react';
import Form from 'react-bootstrap/Form';
import legende from './helper/legende';
import {ValidateMessage} from '../../component/const/ValidateMessage';

import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {dark} from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactDOM from "react-dom";

function textarea(data: any,i: number,optionalFuncSecond:any = false,Store:any) {



  let required = false;
  if (data.required !== undefined && data.required !== null) {
    required = true;
  }

  const handleKeyPress = (e:any) => {
    if(e.key === 'Enter') {
      e.preventDefault()
      alert(<IntlMessage Store={Store} messageId="notification.enter_forbidden"/>)
    }
  }
  const handlefunc  = (e:any) => {
    optionalFuncSecond(e);
  }
  let maxContent = (data.max !== undefined ? data.max : 250)


  const setFormattedContent = (value:any,max:any) => {


    let chars = max - value.length ;

    // @ts-ignore
    document.getElementById('content'+i+data.label).innerHTML = IntlMessageValue('de',"common.remaining_chars",Store,"replace","[n]",chars)
    if(data.seo !== undefined) {
      handlefunc(value)
    }
  }
  const JSONAppend = () => {

    let JsonP = JSON.parse((document.getElementById(data.jsonID) as HTMLInputElement).value);

    let dataValue = (document.getElementById(data.id) as HTMLInputElement).value;

    console.log(JsonP);

    JsonP.item[data.appendJson][data.appendJsonField] = dataValue;

    (document.getElementById(data.jsonID) as HTMLInputElement).value = JSON.stringify(JsonP);

    console.log(JsonP);
  }

  let InitialChars = maxContent - (data.value === null || data.value === undefined ? 0 : data.value.length) ;
  if(data.jsonID !== undefined) {
    return (
        <>
          <Form.Group className="mb-3" controlId={"Form.ControlTextarea" + data.label}
                      key={"Form.ControlTextarea" + data.label}>
            <Form.Label>{data.label}</Form.Label>
            <Form.Control id={data.id} name={data.name} as={data.type} defaultValue={data.value} required={required} onChange={() => JSONAppend()}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
          </Form.Group>
        </>
    );
  } else if (data.forbidden === true) {
    return (
      <>
        <Form.Group className="mb-3" controlId={"Form.ControlTextarea"+i+data.label}
                    key={"Form.ControlTextarea" + data.label}>
          <Form.Label>{data.label}</Form.Label>
          <Form.Control name={data.name} as={data.type} rows={data.row} maxLength={maxContent} defaultValue={data.value} required={required} onKeyDown={(e) => handleKeyPress(e)} onChange={event => setFormattedContent(event.target.value,maxContent)}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        <p id={'content'+i+data.label}>
          <IntlMessage Store={Store} messageId="common.remaining_chars" preperator="replace" param="[n]" replace={InitialChars} />
        </p>
        </Form.Group>
        {legende(data)}
      </>
    );
  } else if(data.nochar !== undefined) {

    const setHighlighter = (e:any,id:any) => {
      let clear = document.getElementById(id) as HTMLDivElement;
      clear.innerHTML = '';
      let newElement = (
          <>
            <SyntaxHighlighter
                showLineNumbers={true}
                language="html"
                style={dark}
                className={'vh80 '}
                wrapLines={true}>
              {e.target.value}
            </SyntaxHighlighter>
          </>
      );
      let temp = document.createElement('div');
      ReactDOM.render(newElement, temp);
      let ele =  document.getElementById(id);
      if(ele !== null) {
        ele.appendChild(temp)
      }
    }

    return (
        <>
          <Form.Group className="mb-3" controlId={"Form.ControlTextarea" + data.label}
                             key={"Form.ControlTextarea" + data.label}>
              <Form.Control className={'editorTextareaModul hideScrollbar'} style={{position: 'relative',height: '80vh',fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace'}} name={data.name} as={data.type} defaultValue={data.value} required={required} onChange={(e) => setHighlighter(e,'SyntaxHighlighter'+data.id)}/>
              <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
            </Form.Group>
              {legende(data)}
            <div id={'SyntaxHighlighter'+data.id} style={{marginTop: '-81.3vh'}}>
              <SyntaxHighlighter
                                 showLineNumbers={true}
                                 language="html"
                                 style={dark}
                                 className={'vh80 pl1point5em'}
                                 wrapLines={true}>
                {data.value}
              </SyntaxHighlighter>
            </div>
        </>
    );
  } else {

    return (
      <>
        <Form.Group className="mb-3" controlId={"Form.ControlTextarea" + data.label}
                    key={"Form.ControlTextarea" + data.label}>
          <Form.Label>{data.label}</Form.Label>
          <Form.Control name={data.name} as={data.type} rows={data.row} maxLength={maxContent} defaultValue={data.value} required={required} onChange={event => setFormattedContent(event.target.value,maxContent)}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
          <p id={'content'+i+data.label}>
            <IntlMessage Store={Store} messageId="common.remaining_chars" preperator="replace" param="[n]" replace={InitialChars} />
          </p>
        </Form.Group>
        {legende(data)}
      </>
    );
  }
}

export default textarea;

