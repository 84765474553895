import React from 'react';
import SunEditorConfig from '../components/SuneditorConfig';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {FormControl, InputGroup, Form} from 'react-bootstrap';
import LanguageSwitcher, {LanguageSwitcherValue} from '../components/LanguageSwitcher';
import BtnBack from '../components/btn_Back';
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Allswimelanes from '../../API/allswimelanes';
import {isEmptyArray} from 'formik';
import {SpinnerJSX} from '../components/Spinner';
import {HelperRowOption} from '../helper/HelperRowOption';
import {HelperSwimelaneOption} from '../helper/HelperSwimelaneOption';
import {HelperTopicOption} from '../helper/HelperTopicOption';
import Alltopic from '../../API/alltopic';
import Allrow from '../../API/allrow';
import Alllabel from '../../API/alllabel';
import ApiURL from '../components/ApiURL';
import {notify_save_intvall_reload} from '../components/notification.service';
import NewIssueProps from '../../Interface/newIssue.interface';


const NewIssue  = (props:NewIssueProps) => {
  const history = useNavigate();
  const user = {
    displayName: "Marcel Ugurcu",
    id: 1
  };
  let swimelane = Allswimelanes();

  let topic = Alltopic();

  let row = Allrow();

  let labels = Alllabel();

  function saveIssue(e: React.FormEvent<HTMLFormElement>, id:any) {

    e.preventDefault();
    let data = e.currentTarget

    var myHeaders = new Headers();

    var formdata = new FormData(data);
    var raw = JSON.stringify({
      "title": formdata.get('headline'),
      "topic": formdata.get('topic'),
      "swimlanes": formdata.get('swimelane'),
      "row":  formdata.get('row'),
      "state":  1,
      "close":  0,
      "tester": "",
      "itMA": "",
      "usercolor": "Orange",
      "created_at": "2021-10-10T14:35:00.000Z",
      "cardColor": formdata.get('cardColor'),
      "IssueTranslation": {
        "description": formdata.get('description'),
      },
      "User": "Marcel Ugurcu"
    });
    let requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw
    };

    fetch(ApiURL()+"issue/insert/1/", requestOptions)
      .then(response => response.text())
      .then(result => reload(result))
      .catch(error => console.log('error', error));

  }

  const reload = (result:any) => {
    console.log(result)
    notify_save_intvall_reload();
    setTimeout(() =>window.location.replace('/ticket/issue/'+result), 3000);
    //setTimeout(() => window.location.reload(), 3000);
  }

  if(swimelane !== undefined && !isEmptyArray(swimelane) &&
    topic !== undefined && !isEmptyArray(topic) &&
    row !== undefined && !isEmptyArray(row) &&
    labels !== undefined && !isEmptyArray(labels)) {



  return (<>
    <div className={'ContentWrapper'}>
      <form onSubmit={(e) => saveIssue(e,0)} >
      <h1><LanguageSwitcher messageId={'create.new.issue'} /></h1>
      <hr />
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1"><LanguageSwitcher messageId={'common.title'} /></InputGroup.Text>
        <FormControl
          placeholder={LanguageSwitcherValue('common.title.placeholder')}
          aria-label="headline"
          name={'headline'}
          aria-describedby="basic-addon1"
          required={true}
        />
        <InputGroup.Text id="basic-addon1"><LanguageSwitcher messageId={'section'} /></InputGroup.Text>
        <Form.Select name={'topic'} required={true}>
          <option></option>
          <HelperTopicOption topic={topic} />
        </Form.Select>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1"><LanguageSwitcher messageId={'swimelane'} /></InputGroup.Text>
      <Form.Select name={'swimelane'} required={true}>
        <option></option>
        <HelperSwimelaneOption swimelane={swimelane} />
      </Form.Select>

        <InputGroup.Text id="basic-addon1"><LanguageSwitcher messageId={'row'} /></InputGroup.Text>
        <Form.Select name={'row'} required={true}>
          <option></option>
          <HelperRowOption row={row} />
        </Form.Select>

        <InputGroup.Text id="basic-addon1"><LanguageSwitcher messageId={'issue.color'} /></InputGroup.Text>
        <Form.Control
          type="color"
          id="cardColor"
          name={'cardColor'}
          defaultValue="#f5f7c4"
          title={LanguageSwitcherValue('common.color.placeholder')}
        />
      </InputGroup>
      <label ><LanguageSwitcher messageId={'common.description'} /></label>
      <SunEditor
        name={'description'}
        height={'350px'}
        setOptions={{
          buttonList: (SunEditorConfig()),
          attributesWhitelist:
            {
              div: 'id',
              p: 'id',
              h: 'id'
            }
        }} />
      <hr />
      <br />
        <input name={'User'} value={user.displayName} type={'hidden'}/>
      <BtnBack history={history} addedClass={''} langKey={'common.back'} />
      <Button className={'float-end'} variant={'success'} type={'submit'}><LanguageSwitcher messageId={'common.save'} /></Button>
      <div style={{clear:'both'}}></div>
      </form>
    </div>

  </>)
} else {
    return (<><SpinnerJSX messageId={'common.loading'} /></>)
  }
}

export default NewIssue;